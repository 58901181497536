<template>
  <v-container class="px-10">

    <v-row >
      <v-form ref="form">
        <v-row >
          <v-col class="mt-8" md="3">
            <v-text-field :rules="requiredRule()" :label="$t('hr.time.from-date')" type="date" 
                          v-model="query.from"></v-text-field>
          </v-col>
          <v-col class="mt-8" md="3">
            <v-text-field :rules="requiredRule()" :label="$t('hr.time.to-date')" type="date" 
                          v-model="query.to"></v-text-field>
          </v-col>
          <v-col class="mt-8" md="3">
            <v-autocomplete :rules="requiredRule()" :items="employeesContracts" item-text="name" item-value="id"
                      :label="$t('hr.time.emp-name')" 
                      v-model="query.employee_contract_id"></v-autocomplete>
          </v-col>
          <v-col md="3">
            <v-btn color="success" class="my-9 text-capitalize" @click="() => {
            
            fetch(query)
            $refs.form.reset()
          }"
            >{{ $t('hr.time.display') }}
            </v-btn>
          </v-col >
          <v-col md="2">
            <v-btn
            color="green"
            class="white--text"
            @click="displayGen = true;gen=true"
          >
            {{ $t("hr.generate-work-hours") }}
          </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn
            color="green"
            class="white--text"
            @click="display = true;newgen=true"
          >
            {{ $t("hr.time.Time Registration") }}
          </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn
            color="red"
            class="white--text"
            @click="displayGen = true;gen=false"
          >
            {{ $t("hr.Delete Work Hours") }}
          </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn color="blue" class="white--text" @click="exportToExcel(query)"
            >{{ $t('hr.time.export') }}
            </v-btn>
          </v-col>

        </v-row>
      </v-form>

      <v-col md="12" class="mt-8">
        <h3 class="mb-2">{{ $router.currentRoute.name }}</h3>
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          id="table"
          :items="data"
          :loading="$store.state.timeAttendance.loading"
          style="border: 1px solid #999"
          :options.sync="$store.state.timeAttendance.options"
          :server-items-length="$store.state.timeAttendance.serverTotal"
          disable-sort
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-center">
              <v-icon
                color="red"
                class="mx-2"
                style="cursor: pointer"
                @click="handleDelete(item)"
              >mdi-delete
              </v-icon>
              <v-icon
                color="blue"
                class="mx-2"
                style="cursor: pointer"
                @click="handleEdit(item)"
              >mdi-pencil
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <generate-modal :gen="gen" v-model="displayGen" :item="item"></generate-modal>
    <ItemModal :newgen="newgen"  v-model="display" :item="item"></ItemModal>
    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              color="green"
              @click="confirm_delete = true"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="red"
              class="white--text"
              depressed
              @click="delete_dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import ItemModal from "@/views/dashboard/modules/HR/time/ItemModal.vue";
import Swal from "sweetalert2";
import {confirmDialog} from "@/helpers/sweetalert";
import {requiredRule} from "@/helpers/validation-rules";
import GenerateModal from "@/views/dashboard/modules/HR/GenerateModal.vue";

export default {
  components: {ItemModal , GenerateModal},
  data() {
    return {
      items: [],
      delete_dialog: false,
      deleteItemId: null,
      confirm_delete: false,
      newgen: false,
      gen: false,
      displayGen: false,
      display: false,
      item: null,
      query: {
        from: '',
        to: '',
        employee_contract_id: '',
      }
    };
  },
  watch: {
    "$store.state.timeAttendance.options": function () {
      this.fetch()
    },
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/hr/work-day/${this.deleteItemId}`).then((res) => {
          
          if (res.status == 200) {
            this.$Notifications(
              "تم الحذف  بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.fetch();
          }
          this.delete_dialog = false;
          this.confirm_delete = false;
        });
      }
    },
  },
  mounted() {
    this.fetch()
    this.fetchEmployeesContracts()
  },
  computed: {
    ...mapGetters({
      data: 'timeAttendance/data',
      headers: 'timeAttendance/headers',
      employeesContracts: 'employees/employeesContracts'
    })
  },
  created(){
    this.fetch(this.query);
    this.fetchEmployeesContracts();
  },
  methods: {
    requiredRule() {
      return requiredRule
    },
    ...mapActions({
      fetch: 'timeAttendance/fetch',
      delete: 'timeAttendance/delete',
      export: 'timeAttendance/export',
      fetchEmployeesContracts: 'employees/fetchEmployeesContracts'
    }),
    handleEdit(item) {
      this.item = item
      this.display = true
      this.newgen = false
    },
    async exportToExcel(query) {
      if (await this.$refs.form.validate()) {
        this.export(this.query)
      }
    },
    async handleDelete(item) {
      this.deleteItemId = item.id;
      this.delete_dialog = true;
    },
  },
};
</script>
<style scoped>
#table >>> th, #table >>> td {
  text-align: center !important;
}
</style>
