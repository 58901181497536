<template>
  <!-- create dialog using vuetify for data entry -->
  <v-dialog width="50%" :value="value" @input="$emit('input', $event)" persistent>
    <v-form v-model="valid" ref="form" @submit.prevent="submit().finally(() => $emit('input', false))" lazy-validation
    >

      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2 v-if="!newgen">
            {{ $t("hr.time.edit") }}
          </h2>
          <h2 v-else>
            {{ $t("hr.time.Time Registration") }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="4">
                <v-text-field v-if="newgen" type="date" :label="$t('hr.time.date')"
                               v-model="form.date">
                </v-text-field>
                <v-text-field v-else :label="$t('hr.time.date')"
                              disabled v-model="form.date">
                </v-text-field>
              </v-col>
              <v-col md="4">
                <v-autocomplete v-if="newgen" :rules="requiredRule()" :items="employeesContracts" item-text="name" item-value="id"
                      :label="$t('hr.time.emp-name')" 
                      v-model="form.employee"></v-autocomplete>
                <v-text-field v-else :label="$t('hr.time.emp-name')" disabled v-model="form.employee">
                </v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field v-if="newgen" :label="$t('note')" type="text"  v-model="form.note">
                </v-text-field>
                <v-text-field v-else :label="$t('hr.time.shift')" disabled v-model="form.employee">
                </v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field :label="$t('hr.time.date-in')" required :rules="requiredRule()" solor type="time" v-model="form.work_day_list[0].in_hour">
                </v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field :label="$t('hr.time.date-out')" required :rules="requiredRule()" solor type="time" v-model="form.work_day_list[0].out_hour">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green"
            class="white--text"
            depressed
            type="submit"
          >{{ $t("submit") }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            depressed
            @click="$emit('input', false)"
          >{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-form>
  </v-dialog>
</template>

<script>

import {requiredRule} from "@/helpers/validation-rules";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ItemModal',
  data() {
    return {
      valid: false,
      form: {
        note: null,
        date: '',
        employee: null,
        shift: {
          name: ''
        },
        work_day_list: [{
          id: 1,
          out_hour: '2020-01-01',
          in_hour: '2020-01-01'
        }]
      }
    }
  },
  mounted() {
    this.fetchEmployeesContracts()
  },
  computed: {
    ...mapGetters({
      
      employeesContracts: 'employees/employeesContracts'
    })
  },
  methods: {
    ...mapActions({
      update: 'timeAttendance/update',
      addNewTime: 'timeAttendance/addNewTime',
      fetchEmployeesContracts: 'employees/fetchEmployeesContracts',
    }),
    requiredRule() {
      return requiredRule
    },
    async submit() {
      if(!this.newgen){
      const valid = await this.$refs.form.validate()
      if (valid) {
        this.update(this.form.work_day_list)
      }}
      if(this.newgen){
        const valid = await this.$refs.form.validate()
      if (valid) {
        this.addNewTime(this.form)
      }
      }


    }
  },
  props: {
    newgen: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  },
  watch: {
    item(nV) {
      this.form = { ...nV }
    }
  }
}
</script>

<style scoped>

</style>
