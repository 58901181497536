<template>
  <!-- create dialog using vuetify for data entry -->
  <v-dialog width="70%" :value="value" @input="$emit('input', $event)" persistent>
    <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation
    >

      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2 v-if="gen">
            {{ $t("hr.time.generate") }}
          </h2>
          <h2 v-else>
            {{ $t("hr.time.Delete") }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="4">
                <v-autocomplete :disabled="false" @input="disabledName($event)" @change="changeType('employee', $event)" :items="employees" item-text="name" item-value="id" outlined :label="$t('hr.employee.emp')">
                </v-autocomplete>
              </v-col>
              <v-col md="4">
                <v-autocomplete @change="changeType('contract', $event)"  :items="contracts" item-text="name" item-value="id" outlined :label="$t('hr.employee.contract')">
                </v-autocomplete>
              </v-col>
              <v-col md="4">
                <v-autocomplete @change="changeType('shift', $event)"  :items="shifts" item-text="name" item-value="id" outlined :label="$t('hr.employee.shift')">
                </v-autocomplete>
              </v-col>
              <v-col md="6">
                <v-text-field outlined :label="$t('hr.time.from-date')" required :rules="requiredRule()" solor
                              type="date" v-model="form.start_date">
                </v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field outlined :label="$t('hr.time.to-date')" required :rules="requiredRule()" solor type="date"
                              v-model="form.end_date">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green"
            class="white--text"
            depressed
            type="submit"
          >{{ $t("submit") }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            depressed
            @click="$emit('input', false)"
          >{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-form>
  </v-dialog>
</template>

<script>

import {requiredRule} from "@/helpers/validation-rules";
import {VSelect} from "vuetify/lib/components";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'GenerateModal',
  components: {
    VSelect,
  },
  data() {
    return {
      disabled_name: false,
      disabled_shift: false,
      disabled_contract: false,
      valid: false,
      form: {
        start_date: '',
        end_date: '',
        type: 'employee',
        cid: '',
      }
    }
  },
  mounted() {
    this.fetchEmployees()
    this.fetchContracts()
    this.fetchShifts()
  },
  computed: {
    ...mapGetters({
      contracts: 'employees/contracts',
      employees: 'employees/employees',
      shifts: 'employees/shifts',
    })
  },
  methods: {
    disabledName(){

    },
    changeType(type, id) {
      this.form.type = type
      this.form.cid = id
    },
    ...mapActions({
      fetchEmployees: 'employees/fetchEmployees',
      fetchContracts: 'employees/fetchContracts',
      fetchShifts: 'employees/fetchShifts',
      fill: 'timeAttendance/fillForMonth',
    }),
    requiredRule() {
      return requiredRule
    },
    async submit() {
      if(this.gen){
      const valid = await this.$refs.form.validate()
      if (valid) {
        this.fill(this.form)
        this.$Notifications(
              "تمت توليد الدوام بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.$emit('input', false)
      }
    }
    if(!this.gen){
      console.log("object");
      const valid = await this.$refs.form.validate()
      if (valid) {
        let params = this.form
        let res = await axios.delete(`/hr/work-day/range`, {params});
        if(res.status==200){
          this.$Notifications(
              "تمت حذف الدوام بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.$emit('input', false)  
        }
      }
    }
  }
  },
  props: {
    gen:{
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  },
  watch: {
    item(nV) {
      this.form = {...nV}
    }
  }
}
</script>

<style scoped>

</style>
